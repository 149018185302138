import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'

import Layout from '@components/layout'
import MustRead from '@components/must-read'

const HydroVacPage = () => {
  return (
    <Layout pageTitle="Hydro-Vac">
      <Container fluid="xxl">
        <Row>
          <Col className="no-gutters">
            <div className="hero-banner large-hero">
              <StaticImage
                className="hero-image"
                src="../../media/hydro_vac_hero.jpg"
                alt=""
                aspectRatio={3.75 / 1}
                layout="fullWidth"
              />
              <div className="hero-fade"></div>
              <div className="hero-text">
                <h2>Services</h2>
                <h1>Hydro-vac</h1>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <main>
        <Container fluid="xxl" className="text-center">
          <Row>
            <Col>
              <h3>
                Wessuc is commited to infrastructure maintenance as a key component of environmental protection. Our goal is to surpass expectations, providing superior hydro-vac services.
              </h3>
            </Col>
          </Row>
        </Container>
        <Container fluid="xxl"><hr/></Container>
        <Container fluid="xxl" className="section default-text">
          <Row xl={2} lg={2} md={1} sm={1} xs={1}>
            <Col>
              <h3>HYDRO-VAC</h3>
              <p>
                Wessuc provides hydro-vac excavation services across Ontario. Hydrovac excavation uses a combination of wet/dry trucks and high-pressure air/water to break up soil, and then vacuums the resulting mixture away. It is used when underground utilities or wires make traditional digging dangerous or unfeasible.
              </p>
              <p>
                Applications for Hydro-vac services:
                <ul>
                  <li>Sub-surface utility engineering</li>
                  <li>Exposing underground utilities</li>
                  <li>Pole holes</li>
                  <li>Daylighting</li>
                  <li>Much more</li>
                </ul>
              </p>
            </Col>
            <Col>
              <StaticImage 
                src="../../media/hydro_vac.jpeg"
                alt="a metalic hydro vac pipe digs a deep hole in the soil" />
            </Col>
          </Row>
        </Container>
      </main>
      <MustRead />
    </Layout>
  )
}

export default HydroVacPage